.capital-container {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}

.area {
  margin-top: 35px;
  margin-right: 30px;
  margin-bottom: 15px;
  font-size: 14px;
  line-height: 25px;
  cursor: pointer;
  color: #121212;

  &:hover {
    color: #0084ff;
  }
}

.area-big {
  margin-top: 35px;
  margin-right: 30px;
  margin-bottom: 15px;
  font-size: 18px;
  line-height: 25px;
  cursor: pointer;
  color: #121212;

  &:hover {
    color: #0084ff;
  }
}

.area-button {
  position: relative;
  margin: 38px 30px 15px -12px;
  padding: 3px 28px 5px 12px;
  overflow: hidden;
  max-width: 250px;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.21;
  white-space: nowrap;
  text-overflow: ellipsis;

  &::after {
    position: absolute;
    top: 8px;
    right: 12px;
    display: block;
    float: right;
    width: 8px;
    height: 8px;
    cursor: pointer;
    content: '';
    background: url('../assets/close.svg');
    background-repeat: no-repeat;
    background-position: 50%;
  }
}

.area-wrapper {
  display: flex;
}
