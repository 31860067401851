.container {
  margin: -12px;
  padding: 12px;
  display: flex;
  align-items: center;
}

.postfix {
  margin-left: 8px;
}
