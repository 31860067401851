.container {
  cursor: pointer;

  & > p {
    white-space: nowrap;
    border-bottom: 1px dotted;
  }

  &.active {
    background-color: var(--black_20);
  }
}
