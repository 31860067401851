.header {
  display: flex;
}

.label {
  margin-right: 10px;
  display: inline-block;
  line-height: 28px;
  vertical-align: top;
  user-select: none;
  color: #121212;
}

.buttons {
  margin-left: auto;
}

.buttons button:first-child {
  margin-right: 10px;
}
