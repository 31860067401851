.container {
  margin: -12px;
  padding-top: 12px;
  width: 260px;
}

.nested-checkbox-groups {
  overflow-y: auto;
  max-height: 312px;

  &:empty {
    display: none;
  }
}
