.root {
  display: flex;
  align-items: center;
}

.underground-time {
  margin-left: 16px;
}

.input-with-label {
  display: inline-flex;
  align-items: center;
}

.input {
  margin-left: 8px;
  width: 80px;
}
