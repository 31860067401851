.root {
  position: relative;

  &.fullfiled {
    &::after {
      position: absolute;
      top: -4px;
      right: -4px;
      width: 8px;
      height: 8px;
      content: '';
      background-color: var(--error_100);
      border-radius: 8px;
    }
  }
}
