.toggle-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  &:hover {
    &::before {
      padding: var(--unit_1, 4px);
      font-size: var(--unit_4);
      content: 'commercial-filters-frontend';
      color: var(--black_100);
      background-color: var(--primary_10);
      border-radius: var(--unit_1, 4px);
    }
  }
}
