.container {
  padding: 4px 12px;
  display: flex;
  background-color: var(--gray6_100);
  border-radius: 4px;

  &:hover {
    background-color: var(--gray10_100);

    & > .close-button {
      background-color: inherit;
    }
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 1px var(--focused_15);
  }
}

.content {
  overflow: hidden;
  font-size: var(--fontSize_14px);
  line-height: var(--lineHeight_20px);
  white-space: nowrap;
  text-overflow: ellipsis;
  user-select: none;
  color: var(--black_100);
}

.close-button {
  margin: 0 0 0 4px;
  padding: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  background: none;
  border: none;
}
