.container {
  display: flex;
  align-items: center;
}

.container > button {
  position: relative;
  padding: 0 10px;
  font-weight: bold;

  &:first-child {
    padding-left: 0;
  }
}

.container > button + button {
  margin-left: 1px;
}

.container > button + button::before {
  position: absolute;
  top: calc(50% - 6px);
  left: -2px;
  width: 1px;
  height: 12px;
  content: '';
  background: var(--gray20_100);
}
