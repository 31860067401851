@import '@cian/ui-kit/colors/variables.css';

.button {
  padding: 3px 0 3px 7px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  background-color: var(--white_100);
  border: 1px solid var(--gray20_100);
  border-radius: 4px;
  transition: all 0.3s;

  &:hover {
    border-color: var(--black_100);
  }

  &:focus {
    border-color: var(--primary_100);
    box-shadow: 0 0 0 1px rgb(131 180 255);
  }

  &--active {
    .arrow-wrapper {
      transform: rotate(180deg);
    }
  }
}

.info-block {
  margin-left: 18px;
  display: flex;
  align-items: center;
}

.count {
  padding: 0 4px;
  background-color: var(--gray6_100);
  border-radius: 4px;
}

.label-wrapper {
  overflow: hidden;
  max-width: 170px;
  text-overflow: ellipsis;
}

.arrow-wrapper {
  padding: 0 7px;
  display: flex;
}
