.container {
  margin: -12px;
  padding: 0;
}

.list {
  margin: 0;
  padding: 0;

  &.with-scroll {
    overflow-y: auto;
  }

  & + .postfix {
    border-top: 1px solid var(--gray10_100);
  }
}

.list-item {
  padding: 8px 12px;
  list-style: none;
  cursor: pointer;

  & label {
    padding-left: 1px;
    display: flex;
    width: 100%;
    cursor: pointer;
    align-items: center;
    pointer-events: none;
  }

  &:hover {
    background: var(--gray6_100);
  }
}

.postfix {
  padding: 12px;
}

.separated {
  border-top: 1px solid var(--gray10_100);

  & + & {
    border-top: none;
    border-bottom: 1px solid var(--gray10_100);
  }
}
