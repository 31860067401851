.container {
  margin: -12px;
  padding-top: 12px;
  width: 260px;
}

.nested-checkbox-groups {
  overflow-y: auto;
  max-height: 345px;
  border-top: 1px solid var(--gray6_100);

  &:empty {
    display: none;
  }
}

.search-field-wrapper {
  padding: 0 12px 12px;
}
