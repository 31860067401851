.filters {
  flex: 1;
  margin: -6px;
  display: flex;
  align-items: center;
}

.filter {
  padding: 6px;
}
