.container {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-template-columns: 1fr 2fr;
  grid-column-gap: 12px;
}

.column {
  display: grid;

  &:nth-child(2) {
    grid-template-columns: 1fr 66px 158px;
    grid-column-gap: 12px;
  }
}

.geosuggest {
  width: 100%;
  height: 28px;
}

@media (max-width: 1279px) {
  .container {
    grid-auto-flow: row;
    grid-template-columns: 1fr;
    grid-row-gap: 12px;
  }

  .column {
    &:first-child {
      order: 2;
    }

    &:last-child {
      order: 1;
    }
  }
}
