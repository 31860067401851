.container {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-template-columns: auto 1fr;
  grid-column-gap: 16px;

  > label {
    margin-right: 8px;
  }
}
