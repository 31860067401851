.filters {
  display: grid;
  grid-row-gap: 56px;
  color: var(--black_100);
}

.filters-group {
  display: grid;
  grid-row-gap: 24px;
}

.filters-group-title {
  width: 140px;
}

.filter-row {
  display: grid;
  grid-template-columns: 140px 1fr;
  grid-column-gap: 40px;
  align-items: baseline;
}
