.container {
  position: relative;
}

.addon-left {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  padding: 0 8px;
  display: flex;
  align-items: center;
  height: 100%;
}

.addon-right {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  height: 100%;
}

.spinner {
  padding: 0 8px;
  display: flex;
  align-items: center;
}

input.input {
  height: auto;
  font-size: 14px;
  line-height: 20px;
  color: var(--black_100);
  border: 1px solid var(--gray20_100);
  border-radius: 4px;
  outline: none;

  &.with-addons {
    padding: 3px 0 3px 32px;
    width: 100%;
  }
}

input.input:hover,
input.input:focus {
  border-color: var(--black_100);
}

input.input::placeholder {
  font-size: 14px;
  line-height: 20px;
  color: var(--gray40_100);
}
