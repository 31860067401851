.container {
  display: flex;
  justify-content: center;
}

.inner {
  padding: 16px;
  min-width: 0;
  min-height: 0;
}
