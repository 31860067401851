.container {
  display: flex;
  flex-direction: column;
}

.options-container {
  margin-bottom: 16px;
  overflow-y: hidden;
  max-height: 60px;
  transition: all 400ms linear;

  &.open {
    max-height: 600px;
  }
}

.expand-button {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.arrow {
  margin-left: 4px;
  display: flex;
  transition: all 400ms linear;

  &.rotated {
    transform: rotate(180deg);
  }
}
