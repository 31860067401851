.container {
  margin: 0 auto;
  padding: 16px 20px;
  align-items: center;
  min-width: 320px;
  max-width: 1376px;
}

.child {
  & + & {
    margin-top: 12px;
  }

  &:empty {
    margin-top: 0;
  }
}
