.column-wrapper {
  padding: 40px;
  display: flex;
}

.column-wrapper-moskow {
  margin: 0 32px;
  padding-top: 60px;
  padding-bottom: 60px;
  display: flex;
}

.column {
  width: 25%;

  &-item {
    padding: 3px 15px 3px 0;
    font-size: 14px;
    word-break: break-word;
    cursor: pointer;

    &:hover {
      color: #0084ff;
    }
  }
}

.column li {
  list-style-type: none;
}

.column ul {
  margin: 0;
  padding: 0;
}

.letter-title {
  margin-top: 35px;
  margin-bottom: 5px;
  font-size: 18px;
  line-height: 1.33;
  word-break: break-word;
  cursor: pointer;
  color: #121212;

  &:hover {
    color: #0084ff;
  }
}

.direction-title {
  margin-bottom: 10px;
  font-size: 14px;
  font-style: italic;
  line-height: 18px;
}

.end-button {
  position: relative;
  margin-top: 2px;
  margin-left: -12px;
  padding: 3px 28px 5px 12px;
  overflow: hidden;
  max-width: 250px;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.21;
  white-space: nowrap;
  text-overflow: ellipsis;

  &::after {
    position: absolute;
    top: 8px;
    right: 12px;
    display: block;
    float: right;
    width: 8px;
    height: 8px;
    cursor: pointer;
    content: '';
    background: url('../assets/close.svg');
    background-repeat: no-repeat;
    background-position: 50%;
  }
}

.end-title-button {
  position: relative;
  margin-top: 33px;
  margin-bottom: 3px;
  margin-left: -16px;
  padding: 3px 30px 3px 16px;
  overflow: hidden;
  max-width: 250px;
  font-size: 18px;
  font-weight: normal;
  line-height: 1.21;
  white-space: nowrap;
  text-overflow: ellipsis;

  &::after {
    position: absolute;
    top: 8px;
    right: 12px;
    display: block;
    float: right;
    width: 10px;
    height: 10px;
    cursor: pointer;
    content: '';
    background: url('../assets/close.svg');
    background-repeat: no-repeat;
    background-position: 50%;
  }
}
