.footer {
  margin: -24px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  background: var(--gray10_80);
}

.with-border {
  border-top: 1px solid var(--gray10_100);
}
