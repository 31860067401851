.container {
  display: flex;
  flex-direction: column;
  width: 480px;
}

.loading-container {
  margin: 24px 0;
  display: flex;
  justify-content: center;
}

.retry-button-wrapper {
  margin-top: 24px;
}
