.option {
  position: relative;
  display: grid;
  cursor: pointer;
  user-select: none;
  grid-auto-flow: column;
  grid-template-columns: 1fr 16px;
  grid-column-gap: 8px;
}

.icon {
  display: flex;
  cursor: pointer;
  align-items: center;
}
