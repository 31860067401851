.root {
  &.with-ellipsis {
    position: relative;

    /* к сожалению такое поведение нельзя
    задать кнопке через пропсы */
    button {
      span {
        overflow: hidden;
        max-width: 184px;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  &.with-counter {
    button {
      padding-right: 33px;
    }
  }

  &.active {
    button {
      background-color: var(--black_20);
    }
  }
}

.counter {
  position: absolute;
  top: calc(50% - 10px);
  right: 12px;
  margin-left: 4px;
  padding: 0 4px;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  color: var(--gray60_100);
  background: var(--gray6_100);
  border-radius: 4px;
}
